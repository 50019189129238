@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --linear-gradient: linear-gradient(45deg, #ac50ef, #7059fb 50%, #2ecff6);

  /* --primary: 222.2 47.4% 11.2%; */
  /* --primary-foreground: 210 40% 98%; */

  /* --secondary: 210 40% 96.1%; */
  /* --secondary-foreground: 222.2 47.4% 11.2%; */

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;

  --ring: 215 20.2% 65.1%;

  --radius: 0.5rem;

  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
    'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

html {
  background-color: black !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  color: white !important;
}

input, textarea, ::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

button {
  border: none;
  outline: none;
}

tr, td, th {
  border: 1px solid gray;
  text-align: center;
}

th {
  background-color: #202232;
}

/* Head and Nav section */
.headNav {
  position: sticky;
  top: 0;
  z-index: 80;
  width: 100%;
}

.navbarStyle {
  display: flex;
  justify-content: space-between;
}

/* Logo and Hamburger Menu block  */
.menu-logo {
  display: table;
}

/* Logo block */
.newnew {
  display: table-cell;
  padding: 10px;
}

.newnew:hover {
  cursor: pointer;
}

.headBar-logo {
  width: auto;
  height: 33px;
}

/* Hamburger Menu block */
.hamburgerMenu {
  display: table-cell;
  z-index: 2;
  vertical-align: middle;
}

.hamburgerOpen {
  background-color: rgb(38, 45, 51) !important;
  width: 100px;
  text-align: center;
}

/* .browse-explore {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  background-color: rgb(38, 45, 51); 
} */


/* Browse Dropdown */
#browse {
  border: 0;
}

#browse:hover {
  background-color: rgb(0, 40, 70);
}

#browse:focus {
  box-shadow: none;
}

#browse::after {
  color: gray;
}

.browse-menu {
  width: 280px;
  line-height: 25px;
  background-color: white !important;
}

/* Explore Dropdown */
#explore {
  border: 0;
}

#explore:hover {
  background-color: rgb(0, 40, 70);
}

#explore:focus {
  box-shadow: none;
}

#explore::after {
  color: gray;
}

.explore-menu {
  width: 280px;
  line-height: 25px;
  background-color: white !important;
}

#exploreSearch {
  margin-bottom: 10px;
  margin-left: 27px;
  margin-top: 10px;
  width: 220px;
  height: 35px;
  border: 1px solid gray;
  font-size: 14px;
  border-radius: 3px;
  padding: 0 10px;
}

#exploreSearch:focus {
  border: 2px solid rgb(60, 140, 275);
  outline: none;
}

.material-icons.browse-explore {
  font-size: 23px;
  position: relative;
  right: 8px;
  top: 5px;
  opacity: 0.6;
  display: inline-block;
}

/* Styling the extra info when hover to the icons in Explore dropdown */
.tooltip.material-icons.browse-explore {
  font-size: 16px;
  float: right;
  position: relative;
  right: 8px;
  top: 5px;
  opacity: 1;
}

.tooltip.material-icons.browse-explore .tooltiptext.info {
  visibility: hidden;
  background-color: rgb(32, 32, 32);
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
  border-radius: 6px;
  padding: 3px 3px;
  position: absolute;
  left: 140%;
}

.tooltip.material-icons.browse-explore:hover .tooltiptext.info {
  visibility: visible;
}

/* Styling the launch app button */
.headerbar {
  z-index: 80;
  /* line-height: 64px; */
  /* color: white; */
  display: flex;
  background-color: #202232;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  width: 100%;
}

/* Styling the launch app button */
.launchApp-link {
  margin: 5px 0px 5px 0px;
  height: 40px;
  width: 200px;
  display: flex;
}

.launchApp-link:hover {
  text-decoration: none;
}

.button-launchApp {
  background-color: #446FE1;
  height: 40px;
  width: 250px;
  border-radius: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 850px) {
  .launchApp-link {
    width: 170px !important;
  }

  .button-launchApp {
    width: 160px !important;
  }
}

@media only screen and (max-width: 700px) {
  .launchApp-link {
    width: fit-content !important;
  }

  .button-launchApp {
    width: fit-content !important;
  }
}

/* Styling user icon on headBar */
.user-info {
  display: flex;
  justify-content: space-between;
}

#user {
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#user::after {
  content: none;
}

#user:hover {
  background-color: rgb(0, 40, 70);
}

#user:focus {
  box-shadow: none;
}

.user-menu {
  width: 220px;
  line-height: 30px;
  background-color: white !important;
}

/*
Ipad Air custom 820px * 1180px
Ipad Mini custom 768px * 1024px
*/
@media only screen and (max-width: 820px) {
  .user-menu {
    width: 160px;
    line-height: 20px;
  }
}

#search {
  border: 0;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#search::after {
  content: none;
}

#search:hover {
  background-color: rgb(0, 40, 70);
}

#search:focus {
  box-shadow: none;
}

/* Navigation Bar */
#home-page {
  width: 100%;
}

.navigation {
  z-index: 60;
  width: 100%;
  position: sticky;
}

.navbar-nav {
  justify-content: center;
  width: 100%;
}

.text {
  font-family: inherit;
  margin: 0px;
  padding: 5px 12px;
  min-width: 0px;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.text1 {
  font-family: inherit;
  margin: 0px;
  padding: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: white;
  /* color: red;  */
}

.text2 {
  font-family: inherit;
  margin: 0px;
  padding: 0px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgb(112, 119, 129);
  /* color: red;  */
}

.texticon {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  list-style-type: none;
  height: 45px;
  min-height: 36px;
  outline: none;
  text-decoration: none;
  text-align: -webkit-match-parent;
  padding: 0px 10px;
  color: white;
}

.texticon:hover {
  background-color: #2e343f;
  height: 45px;
  padding: 0px 10px;
  border-radius: 10px;
}

.buttonclass:focus {
  outline: none;
}

#toolbar-dropdown {
  padding: 12px 12px 0 12px;
  color: rgb(155, 158, 163);
  height: 45px;
}

#more-menu {
  padding: 0px 10px;
  margin: 0;
}

.dropdown-menu.show {
  background-color: #1f2436;
  margin: 0;
  padding: 0;
  right: 0;
  left: auto;
}

.flex-grid {
  display: flex;
}

nav {
  background-color: #0D111C;
  height: 50px;
  color: rgb(155, 158, 163);
  padding: 0rem;
  margin: 0px;
}

.navbar {
  padding: 0rem;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0rem;
  padding-left: 0rem;
}

.nav-link {
  padding: 0px;
}

.nav-link button {
  margin: 0px;
}

.link.explore {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.link.tutorial {
  width: 120px;
  height: 30px;
  text-decoration: none !important;
}

:global(.css-text-146c3p1) {
  text-decoration: none !important;
}

.card {
  background-color: transparent !important;
  border: 0;
  border-radius: 25px;
  width: 100%;
  flex-wrap: wrap;
  padding: 5px;
  margin: 0;
}

.sectionGrid {
  display: grid;
  width: 100%;
  grid-auto-rows: 1fr !important;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.sectionCard {
  height: 100% !important;
  padding-top: 20px !important;
  background-color: rgba(32, 34, 50, 0.5) !important;
  border: 1px solid gray !important;
  border-radius: 25px !important;
}

.sectionCard:hover {
  border-width: 1px !important;
  border-color: #6272FA !important;
}

.flex-type {
  flex-direction: row;
}

@media only screen and (max-width: 700px) {
  .flex-type {
    flex-direction: column;
  }
}

.card-body {
  background: transparent !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer {
  background: transparent !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card.tutorial {
  padding: 4px 0;
  display: inline-block;
  background: var(--linear-gradient);
  color: white !important;
  width: 120px;
  height: 35px;
  margin: 0;
  border-radius: 4px;
}

.card.tutorial:hover {
  box-shadow: 1px 1px 5px 1px #9D7CFF;
}

.material-icons {
  font-size: 200px;
  color: rgb(155, 158, 163);
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-40 {
  font-size: 40px;
  justify-content: center;
}

.material-icons.md-12 {
  font-size: 12px;
  color: black;
  text-decoration: none !important;
}

/* Tutorial Page */
.tutorialPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tutorialSelect {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 300px;
  height: 50px;
  margin-top: -30vh;
}

#sm {
  max-width: 320px;
  margin-top: -25%;
  margin-bottom: 15%;
}

#sm1 {
  max-width: 225px;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: left;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 4px;
  overflow: hidden;
  background-color: #1f2436;
  position: -webkit-sticky;
  /**position: fixed;**/
  top: 0;
  width: 100%;
}

li {
  float: left;
  border-right: 1px solid #1f2436;
}

li:last-child {
  border-right: none;
  color: rgb(38, 45, 51);
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 4px 15px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: rgb(66, 66, 70);
}

.active {
  background-color: #1f2436 !important;
  border-radius: 10px;
  /* #9B9EA3; */
}

.custom-dropdown {
  background-color: #202232 !important;
}

.custom-dropdown:hover {
  background-color: #0D111C !important;
}

.material-icons.md-20 {
  font-size: 20px;
}

.material-icons.md-200 {
  font-size: 200px;
}

/* Styling the Login Page */
.login {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  min-height: 100vh;
}

input[type="text"],
input[type="password"] {
  height: 45px;
  width: 350px;
}

input[type="text"]:focus,
input[type="password"]:focus {
  outline: none;
  border: 2px solid gray;
}

input[type="submit"] {
  height: 45px;
  width: 350px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background: var(--linear-gradient);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
}

input[type="submit"]:hover {
  opacity: 0.9;
}

.button-container-login {
  display: flex;
  justify-content: center;
}

.list-container-login {
  display: flex;
}

.error-login {
  color: red;
  font-size: 12px;
}

.searchInput {
  border: none !important;
}

.title-login {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-container-login {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
}

.title-login {
  color: white;
  margin: 0;
}

.field-name-login {
  color: white;
}

.welcome-login {
  color: white;
  font-size: 14px;
  margin: 0;
}

.foot-login {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 10px 10px 10px;
}

.trust-message {
  align-items: center;
}

.label-trust {
  color: white;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

#trust-login {
  margin-right: 6px;
  padding: 0;
  margin-bottom: 0;
}

#forgotPass {
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

/*
End for Login Page
*/

.outer {
  background: rgb(255, 255, 255);
  font-family: Roboto, "Noto Sans", "Noto Sans JP", "Noto Sans CJK KR",
    "Noto Sans Arabic UI", "Noto Sans Devanagari UI", "Noto Sans Hebrew",
    "Noto Sans Thai UI", Helvetica, Arial, sans-serif;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
}

.inner {
  height: 36px;
  position: sticky;
}

.navdiv {
  font-family: inherit;
  margin: 0px auto;
  padding: 0px;
  background-color: rgb(31, 36, 54);
  display: flex;
  width: 100%;
  position: fixed;
  flex-direction: row;
  flex-basis: auto;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  padding: 0px;
  background-color: rgb(31, 36, 54);
  display: flex;
  width: 100%;
  position: fixed;
  flex-direction: row;
  flex-basis: auto;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  position: sticky;
}

.onelist {
  font-family: inherit;
  list-style: none;
  margin: 0px;
  padding: 0px;
  min-width: 12rem;
  width: 100%;
  flex-direction: row;
}

.element {
  font-family: inherit;
  margin: 0px;
  padding: 0px;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  margin-top: 0px;
  margin-left: -30px;
  width: 1500px;
  margin-bottom: 0px;
  flex-direction: row;
  flex-wrap: wrap;
}

.link {
  color: -webkit-link;
  cursor: pointer;
  background-color: rgb(31, 36, 54);
  text-decoration: none;
  position: -webkit-sticky;
}

.buttonclass {
  background: transparent;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex: 1 1 0%;
  font-size: inherit;
  font-weight: inherit;
  margin: 0px;
  min-width: 0px;
  outline: none;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.button {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  transition-duration: 0.4s;
}

.icon {
  margin-right: 3px;
  align-self: center;
  display: flex;
}

/* Styling 404 page */
.fourOfour {
  height: 100vh;
  background-color: #131722;
}

.fourOfourText {
  /* margin-top: 100px;  */
  padding-top: 100px;
  margin-bottom: 30px;
}

/* ETrade page styling  */
.etrade-view {
  background-color: #1e222d;
}

.etrade-embed {
  display: inline-block;
}

.buy-sell {
  display: inline-block;
}

#cryptoName {
  position: absolute;
  top: 200px;
  width: 20%;
  right: 2.5%;
}

#nameCrypto {
  display: block;
  width: 100%;
  border: solid 3px gray;
  padding: 0 5px;
  border-radius: 5px;
}


.cryptoInfo {
  position: absolute;
  top: 280px;
  width: 20%;
  right: 2%;
}

#stockName {
  position: absolute;
  top: 500px;
  width: 20%;
  right: 2.5%;
}

#nameStock {
  display: block;
  width: 100%;
  border: solid 3px gray;
  padding: 0 5px;
  border-radius: 5px;
}

.info {
  position: absolute;
  top: 580px;
  width: 20%;
  right: 2%;
}

.button-sell {
  position: absolute;
  top: 848px;
  color: #C1C6CC;
  background-color: #F23645;
  padding: 10px 30px;
  height: 70px;
  width: 20%;
  right: 2.5%;
  font-size: 16px;
  border: solid 1px;
  border-color: gray;
  border-radius: 5px;
}

.button-sell:focus {
  outline-color: gray;
}

.button-buy:focus {
  outline-color: gray;
}

.button-buy {
  position: absolute;
  top: 920px;
  color: #C1C6CC;
  background-color: #2962FF;
  padding: 10px 30px;
  height: 70px;
  width: 20%;
  right: 2.5%;
  font-size: 16px;
  border: solid 1px;
  border-color: gray;
  border-radius: 5px;
}


/* .bitcoin-name {
  position:absolute; 
  top: 185.5px; 
  padding: 5px 20px; 
  width: 25%; 
  text-align: center;
} */

#units {
  position: absolute;
  top: 1020px;
  width: 20%;
  right: 2.5%;
}

#quantity {
  display: block;
  width: 100%;
  border: solid 3px gray;
  padding: 0 5px;
  border-radius: 5px;
}

#quantity:focus {
  outline-color: gray;
}

.button-buysell {
  position: absolute;
  top: 1100px;
  color: white;
  padding: 10px 30px;
  right: 2.5%;
  width: 20%;
  height: 80px;
  font-size: 16px;
  border: solid 1px;
  border-color: gray;
  border-radius: 5px;
}

.button-buysell:focus {
  outline-color: gray;
}

/* Styling Tearsheet */
.selectContainer {
  height: 60x;
}

.benchSelectDiv {
  height: 60px;
}

#tableTitle {
  height: 44px;
}

#table {
  height: 1710px;
}

#eoyTitle {
  height: 32px;
}

#eoy_table {
  height: 398px;
}

#w_draw_table_Title {
  height: 32px;
}

#w_draw_table {
  height: 398px;
}

.plot {
  border-radius: 8px;
  overflow: hidden;
}

.tables {
  border-radius: 8px;
  overflow: hidden;
}

.big {
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 8px;
  overflow: hidden;
}

/* Styling Benchmark Tearsheet */
/* #tableTitleBench {
  height: 44px; 
}

#table_bench {
  height: 1710px; 
}

#eoyTitleBench {
  height: 32px; 
}

#eoy_table_bench {
  height: 398px; 
}

#w_draw_table_Title_Bench {
  height: 32px; 
}

#w_draw_table_bench {
  height: 398px; 
} */